<h5 class="card-header">{{ "terms.title" | translate }}</h5>
<br />
<h5 class="card-title">{{"terms.subtitle" | translate }}</h5>
<p class="card-text">{{"terms.p1" | translate }}
    <a href="https://cmlexports.com" class="">https://cmlexports.com</a> {{"terms.p2" | translate }}
    <a href="https://system.cmlexports.com" class="">https://system.cmlexports.com</a> {{"terms.p3" | translate }} <strong>CML Exports Inc.</strong>, {{"terms.p4" | translate }}
    <a href="www.system.cmlexports.com" class="">www.system.cmlexports.com</a> {{"terms.p5" | translate }}
    <strong>{{ "terms.client" | translate }}</strong>
</p>
<h5 class="card-text">{{"terms.t1" | translate }}</h5>
<p class="card-text">{{"terms.t1a" | translate }}</p>
<h5 class="card-text">{{"terms.t2" | translate }}</h5>
<p class="card-text">
    <br />{{"terms.t2a" | translate }}
    <br />{{"terms.t2b" | translate }}
    <br />{{"terms.t2c" | translate }}
    <br />{{"terms.t2d" | translate }}
    <br />{{"terms.t2e" | translate }}
</p>
<h5 class="card-text">{{"terms.t3" | translate }}</h5>
<p class="card-text">
    <br />{{"terms.t3a" | translate }}
    <br />{{"terms.t3b" | translate }}
    <br />{{"terms.t3c" | translate }}
    <br />{{"terms.t3d" | translate }}
    <br />{{"terms.t3e" | translate }}
    <br />{{"terms.t3f" | translate }}
    <br />{{"terms.t3g" | translate }}
    <br />{{"terms.t3h" | translate }}
    <br />{{"terms.t3i" | translate }}
    <br />{{"terms.t3j" | translate }}
    <br />{{"terms.t3k" | translate }}
    <br />{{"terms.t3l" | translate }}
    <br />{{"terms.t3m" | translate }}
    <br />{{"terms.t3n" | translate }}
    <br />{{"terms.t3o" | translate }}
    <br />{{"terms.t3p" | translate }}
    <br />{{"terms.t3q" | translate }}
    <br />{{"terms.t3r" | translate }}
    <br />{{"terms.t3s" | translate }}

</p>
<h5 class="card-text">{{"terms.t4" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t4a" | translate }}
    <Br/>{{"terms.t4b" | translate }}
    <Br/>{{"terms.t4c" | translate }}
    <Br/>{{"terms.t4d" | translate }}
    <Br/>{{"terms.t4e" | translate }}
    <Br/>{{"terms.t4f" | translate }}
    <Br/>{{"terms.t4g" | translate }}
</p>
<h5 class="card-text">{{"terms.t5" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t5a" | translate }}
    <Br/>{{"terms.t5b" | translate }}
    <Br/>{{"terms.t5c" | translate }}
    <Br/>{{"terms.t5d" | translate }}
    <Br/>{{"terms.t5e" | translate }}
    <Br/>{{"terms.t5f" | translate }}
    <Br/>{{"terms.t5g" | translate }}
</p>
<h5 class="card-text">{{"terms.t6" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t6a" | translate }}
    <Br/>{{"terms.t6b" | translate }}
    <Br/>{{"terms.t6c" | translate }}
    <Br/>{{"terms.t6d" | translate }}
</p>
<h5 class="card-text">{{"terms.t7" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t7a" | translate }}
    <Br/>{{"terms.t7b" | translate }}
    <Br/>{{"terms.t7c" | translate }}
    <Br/>{{"terms.t7d" | translate }}
    <Br/>{{"terms.t7e" | translate }}
    <Br/>{{"terms.t7f" | translate }}
    <Br/>{{"terms.t7g" | translate }}
    <Br/>{{"terms.t7h" | translate }}
    <Br/>{{"terms.t7i" | translate }}
    <Br/>{{"terms.t7j" | translate }}
</p>
<h5 class="card-text">{{"terms.t8" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t8a" | translate }}
    <Br/>{{"terms.t8b" | translate }}
    <Br/>{{"terms.t8c" | translate }}
    <Br/>{{"terms.t8d" | translate }}
    <Br/><span class="spacing"> * {{"terms.t8d1" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t8d2" | translate }}</span>
</p>
<h5 class="card-text">{{"terms.t9" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t9a" | translate }}
    <Br/>{{"terms.t9b1" | translate }}
    <Br/>{{"terms.t9b2" | translate }}
    <Br/>{{"terms.t9c" | translate }}
    <Br/>{{"terms.t9d" | translate }}
    <Br/>{{"terms.t9e" | translate }}
</p>
<h5 class="card-text">{{"terms.t10" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t10a" | translate }}
    <Br/>{{"terms.t10b" | translate }}
    <Br/>{{"terms.t10c" | translate }}
    <Br/>{{"terms.t10d" | translate }}
    <Br/>{{"terms.t10e" | translate }}
    <Br/>{{"terms.t10f" | translate }}
    <Br/>{{"terms.t10g" | translate }}
    <Br/>{{"terms.t10h" | translate }}
</p>
<h5 class="card-text">{{"terms.t11" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t11a" | translate }}
    <Br/>{{"terms.t11b" | translate }}
    <Br/>{{"terms.t11c" | translate }}
    <Br/>{{"terms.t11d" | translate }}
    <Br/>{{"terms.t11e" | translate }}
    <Br/>{{"terms.t11f" | translate }}
    <Br/>{{"terms.t11g" | translate }}
    <Br/>{{"terms.t11h" | translate }}
    <Br/>{{"terms.t11i" | translate }}
</p>
<h5 class="card-text">{{"terms.t12" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t12a" | translate }}
    <Br/>{{"terms.t12b" | translate }}
    <Br/>{{"terms.t12c" | translate }}
    <Br/>{{"terms.t12d" | translate}}
    <Br/>{{"terms.t12e" | translate}}
    <Br/> <span class="italic">(I) </span> {{"terms.t12e1" | translate }}
    <span class="italic">(II) </span> {{"terms.t12e2" | translate }}
    <span class="italic">(III) </span> {{"terms.t12e3" | translate }}
    <span class="italic">(IV) </span> {{"terms.t12e4" | translate }}
    <Br/>{{"terms.t12f" | translate }}
</p>
<h5 class="card-text">{{"terms.t13" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t13a" | translate }}
    <Br/>{{"terms.t13b" | translate }}
    <Br/>{{"terms.t13b1" | translate }}
    <Br/>{{"terms.t13c" | translate }}
    <Br/>{{"terms.t13d" | translate }}
    <Br/>{{"terms.t13e" | translate }}
    <Br/>{{"terms.t13f" | translate }}
    <Br/>{{"terms.t13g" | translate }}
    <Br/><span class="spacing"> * {{"terms.t13g1" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13g2" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13g3" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13g4" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13g5" | translate }}</span>
    <Br/>{{"terms.t13h" | translate }}
    <Br/>{{"terms.t13i" | translate }}
    <Br/>{{"terms.t13j" | translate }}
    <Br/>{{"terms.t13k" | translate }}
    <Br/>{{"terms.t13l" | translate }}
    <Br/><span class="spacing"> * {{"terms.t13l1" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13l2" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13l3" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13l4" | translate }}</span>
    <Br/><span class="spacing"> * {{"terms.t13l5" | translate }}</span>
</p>
<h5 class="card-text">{{"terms.t14" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t14a" | translate }}
    <Br/>{{"terms.t14b" | translate }}
</p>
<h5 class="card-text">{{"terms.t15" | translate }}</h5>
<p class="card-text">
    <Br/>{{"terms.t15a" | translate }}
    <Br/>{{"terms.t15b" | translate }}
    <Br/>{{"terms.t15c" | translate }}
</p>