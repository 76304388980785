import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SERVICES, Service } from '../../services/services.service';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html'
})

export class DetailsComponent implements OnInit {

  serv: any = {};

  constructor(
      private activatedRouter: ActivatedRoute,
      private service: SERVICES ) {

      this.activatedRouter.params.subscribe( params => {
        this.serv = this.service.getService(params ['idx']);

      });

      }

  ngOnInit() {
  }

}
