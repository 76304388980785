
import { Injectable } from '@angular/core';

@Injectable ()

export class SERVICES {

    private services: Service [] = [
        {
            name: "Door to Door Service and Cargo Consolidation",
            details: "From the start of the order to the final delivery of the shipment, CML Exports and Moving Solutions Inc. Inc can coordinate the collection, packaging, labeling, storage and delivery. We partner with port managers and warehouses to ensure that all our client's needs are met. Our full line of trucks is ready at a moment's notice.",
            img: "assets/img/services/008-Consolidation.JPG"
        },
        {
            name: "Cargo Transportation Specialists",
            details: "We are the solution for all your cargo transportation needs, be it Air, Maritime or Terrestrial. No matter the characteristic of your cargo or the place of destination. The main objective of CML Exports will always be to keep your merchandise in top condition, we guarantee the transfer of your materials as we have the best transport equipment for your shipment, we offer excellent customer service and first-class logistics.", 
            img: "assets/img/services/009-Transport.jpg"
        },

        {
            name: "International Locker",
            details: "If you like to buy online and what you need is in another country. Do not worry, at CML Exports and Moving Solutions Inc. Inc we offer you the possibility of acquiring an international locker in Houston at a low annual cost. You can choose the one that best suits your need, do not miss the opportunity to acquire all your products with the supplier you want is very easy, simple and safe to acquire your locker, we are ready and ready to send your product to any part of the world, just contact us and we will take care of the whole procedure.Estamos orientados a convertirnos en la conexión perfecta entre usted y su producto.",
            img: "assets/img/services/003-lockerCML.jpg"
        },
        {
            name: "Packaging of Medical Equipment",
            details: "Our work supports us, offices, hospitals, charities, any institution that requires medical equipment that could only be obtained in the United States. At CML Exports and Moving Solutions Inc. Inc we have the ability to send any type of medical equipment to any part of the world, with the security we provide you can send from the smallest but delicate to the largest and heaviest medical team, our staff is constantly trained to provide the best service, we are ready to provide all the necessary help to carry out from the packaging to the transfer of your equipment be assured that we take care of both your merchandise and take care of our reputation.",
            img: "assets/img/services/004-MedicalEquipment.jpg"
        },
        {
            name: "Packaging and Warehouse Service",
            details: "The correct packaging of your merchandise can avoid any damage or deterioration of the same, in CML Exports and Moving Solutions Inc. Inc we provide a full range of packaging options, specially designed so that your merchandise can support national or international trips where regularly suffer from constant movements; We use the highest quality materials. We also have a storage service where you can safeguard your cargo for as long as you consider necessary for an accessible and fair cost.", 
            img: "assets/img/services/005-Packaging.jpg"
        },
        {
            name: "Ship of Heavy Machinery",
            details: "At CML Exports and Moving Solutions Inc., we have highly trained personnel to meet the requirements of your product in a safe and timely manner, such as: machinery, tools, supplies, etc. We carry out the transfer of any type of machinery, we create the perfect packaging for your shipment, that is why we guarantee that your product will arrive in optimal conditions, your peace of mind is our priority.",
            img: "assets/img/services/006-ShipMachinery.jpg"
        },
        {
            name: "Boats and Special Containers",
            details: "We always look for your goods to travel safely, we inspect any type of packaging choosing always the one that best suits the characteristics of your merchandise ... This with the purpose of fulfilling our commitment 100%; that your investment reaches its destination in the best conditions.",
            img: "assets/img/services/001-SpecialContainer.jpg"
        },
        {
            name: "Agent of Commercial and Residential Removals",
            details: "At CML Exports we can coordinate the different stages of your process (lifting, labeling, storage and delivery). We partner with the best managers of ports and warehouses and in the same way through our commercial alliances we can provide a full range of transportation according to the needs of each of our customers. If you need national or international shipping, we are ready to ship your products to any part of the world.",
            img: "assets/img/services/002-AgentRemovals.jpeg"
        },
        {
            name: "Parcel Service and Shipping",
            details: "It sent and received documents and parcels to Mexico, Guatemala, El Salvador, Honduras, Nicaragua, Panama, Colombia, Venezuela, Peru, Dominican Republic, and Ecuador from Houston Texas and / or USA to name a few. Send your packages to any part of the world, in a 100% reliable way. CML Exports and Trucking Inc offers the possibility of sending your packages easily and safely.",
            img: "assets/img/services/007-Shipping.jpg"
        }
  ];

        constructor() {}

          getServices(): Service [] {
            return this.services;
          }

          getService( idx: string ) {
            return this.services[ idx ];
          }
}

export interface Service {
        name: string;
        details: string;
        img: string;
      }
