<div class="container">
    <h1>{{ "titleRestrictions" | translate }}</h1>
    <p class="card-text text-justify">
        {{ "restrictions.p1" | translate }}
    </p>

    <span class="tab"> * {{ "points.1" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.2" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.3" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.4" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.5" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.6" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.7" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.8" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.9" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.10" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.11" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.12" | translate }} </span>
    <br />
    <span class="tab"> * {{ "points.13" | translate }} </span>
    <br />
    <br />

    <p class="card-text text-justify">
        {{ "restrictions.p2" | translate }}
    </p>
    <p class="card-text text-justify">
        {{ "restrictions.p3" | translate }}
    </p>
    <p class="card-text text-justify">
        {{ "restrictions.p4" | translate }}
    </p>
    <p class="card-text text-justify">
        {{ "restrictions.p5" | translate }}
    </p>
    <p class="card-text text-justify">
        {{ "restrictions.p6" | translate }}
    </p>
</div>