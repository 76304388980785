<h1> {{ "membership.title" | translate }} </h1>
<p class="card-text">
    {{ "membership.paragraph" | translate }}
</p>
<div class="card-group" style="max-width:95%; padding-left: 5%;">
    <div class="card border-primary mb-3">
        <div class="card-header basic bg-transparent border-primary">
            {{ "membership.basic" | translate }}
        </div>
        <div class="card-body txt"> 
            <p class="body-membership"> {{ "detB.op1" | translate }} </p>
            <p class="body-membership"> {{ "detB.op3" | translate }} </p>
            <p class="body-membership"> {{ "detB.op2" | translate }}
                <br /> ({{ "membership.e1" | translate }} 3 {{ "membership.pound" | translate }})
            </p>
            <p class="body-membership"> {{ "detB.op4" | translate }} </p>
        </div>
        <div class="card-footer basic bg-transparent border-primary" style="text-align: center; color: yellow !important;">
            <span class="price"> {{ "membership.free" | translate }} </span>
        </div>
    </div>
    <div class="card border-primary mb-3">
        <div class="card-header premium bg-transparent border-primary">
            {{ "membership.premium" | translate }}
        </div>
        <div class="card-body txt-">
            <p class="body-membership"> {{ "detP.op1" | translate }} </p>
            <p class="body-membership"> {{ "detP.op3" | translate }} </p>
            <p class="body-membership"> {{ "detP.op2" | translate }} ({{ "membership.e1" | translate }} 3 {{ "membership.pound" | translate }})
            </p>
            <p class="body-membership"> {{ "detP.op4" | translate }}
                <sup> 1 </sup>
                <br /> {{ "membership.box" | translate }}
            </p>
            <p class="body-membership"> {{ "detP.op5" | translate }}
                <sup> 2 </sup>
                <br /> {{ "membership.box" | translate }}
            </p>
        </div>
        <div class="card-footer premium bg-transparent border-primary" style="text-align: center; color: yellow !important;">
            <span class="price"> $5 USD/{{ "membership.month" | translate }} </span>
        </div>
    </div>
    <div class="card border-primary mb-3">
        <div class="card-header corpo bg-transparent border-primary">
            {{ "membership.corporative" | translate }}
        </div>
        <div class="card-body txt-">
            <p class="body-membership"> {{ "detC.op1" | translate }} </p>
            <p class="body-membership"> {{ "detC.op3" | translate }} </p>
            <p class="body-membership"> {{ "detC.op2" | translate }} ({{ "membership.e1" | translate }} 3 {{ "membership.pound" | translate }})
            </p>
            <p class="body-membership"> {{ "detC.op4" | translate }}
                <sup> 1 </sup>
                <br /> {{ "membership.box" | translate }}
            </p>
            <p class="body-membership"> {{ "detC.op5" | translate }}
                <sup> 2 </sup>
                <br /> {{ "membership.box" | translate }}
            </p>
        </div>
        <div class="card-footer corpo bg-transparent border-primary" style="text-align: center; color: yellow !important;">
            <span class="price"> $10 USD/{{ "membership.month" | translate }} </span>
        </div>
    </div>
</div>

<p class="card-text">
    {{ "membership.p1" | translate }}
</p>
<p class="card-text">
    {{ "membership.p2" | translate }}
</p>
<p class="card-text">
    {{ "membership.p3" | translate }}
</p>

<div class="text-sup" style="max-width: 95%;">
    <sup>1</sup>
    <span>
        {{ "membership.sup1" | translate }}
    </span>
    <br />
    <sup>2</sup>
    <span>
        {{ "membership.sup2" | translate }}
    </span>
</div>