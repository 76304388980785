<h1 style="text-align: center;"> {{ "Documents.title" | translate }} </h1>
<p class="text-justify">
    {{ "Documents.paragraph" | translate }}
</p>

<div class="card text-center">
    <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link" routerLink="/documents">
            {{ "Documents.locker.locker" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/commission">
            {{ "Documents.commission.commission" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link active" routerLink="/payments">
            {{ "Documents.Payments.payment" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/example">
            {{ "Documents.TaxInsur.TaxInsur" | translate }}
            </a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <h1> {{ "Documents.Payments.Payments" | translate }} </h1>
        <p class="text-justify">
            {{ "Documents.Payments.p1" | translate }}
        </p>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th rowspan="2" scope="col" style="vertical-align: middle;"> {{ "Documents.Payments.user" | translate }} </th>
                    <th rowspan="2" scope="col" style="vertical-align: middle;"> {{ "Documents.Payments.income" | translate }} </th>
                    <th colspan="2" scope="col"> {{ "Documents.Payments.commission" | translate }} </th>
                </tr>
                <tr>
                    <th scope="col"> {{ "Documents.Payments.seller" | translate }} </th>
                    <th scope="col"> Supervisor </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="styleth" scope="row"> {{ "Documents.Payments.seller" | translate }} </td>
                    <td> $ 10.00 USD </td>
                    <td> {{ "Documents.Payments.apply" | translate }} </td>
                    <td> {{ "Documents.Payments.apply" | translate }} </td>
                </tr>
                <tr>
                    <td class="styleth" scope="row"> {{ "Documents.Payments.agency" | translate }} </td>
                    <td> $ 5.00 USD </td>
                    <td> 4% </td>
                    <td> 6% </td>
                </tr>
                <tr>
                    <td class="styleth" scope="row"> {{ "Documents.Payments.locker" | translate }} </td>
                    <td> $ 3.00 USD </td>
                    <td> 2% </td>
                    <td> 4% </td>
                </tr>
            </tbody>
        </table>
        <p class="text-justify">
            {{ "Documents.Payments.p2" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.Payments.p3" | translate }}
        </p>
    </div>
</div>