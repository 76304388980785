import { Person } from "./person";

export type User = {
  id: number;
  agencyName: string | null;
  name: string | null;
  alphanumeric?: string;
  email: string;
  email_verified_at: string;
  tier: number;
  status: number;
  activated: number;
  invitation: number;
  online: number;
  completed?: number;
  type_pay: number;
  image_admin?: string;
  admin_name?: string;
  es_date?: string;
  user_id: number | null;
  commission: number;
  app_ios: string;
  app_android: string;
  agencyId?: string;
  creator: User | null;
  person: Person;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
  reseller?: User[];
  agency?: User;
  role: Role;
};

export interface Role {
  id: number;
  user_id: number;
  name: string;
  name_en?: string;
  type: number;
  agency?: User;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export const TierId = Object.freeze({
  TIER_ADMIN: 1,
  TIER_MANAGEMENT: 2,
  TIER_ACCOUNTING: 3,
  TIER_SUPPORT: 4,
  TIER_AGENCY: 5,
  TIER_STORER: 6,
  TIER_ASSISTANT: 7,
  TIER_DRIVER: 8,
  TIER_DISPATCHER: 9,
  TIER_RRHH: 10,
  TIER_LOCKERS: 11,
  TIER_SUPERVISOR: 12,
  TIER_SELLER: 13,

  getDisplayName(tier: number) {
    switch (tier) {
      case TierId.TIER_ADMIN:
        return "administrator";
      case TierId.TIER_MANAGEMENT:
        return "management";
      case TierId.TIER_ACCOUNTING:
        return "accounting";
      case TierId.TIER_SUPPORT:
        return "support";
      case TierId.TIER_AGENCY:
        return "agency";
      case TierId.TIER_STORER:
        return "storer";
      case TierId.TIER_ASSISTANT:
        return "assistant";
      case TierId.TIER_DRIVER:
        return "driver";
      case TierId.TIER_DISPATCHER:
        return "dispatcher";
      case TierId.TIER_RRHH:
        return "humanResources";
      case TierId.TIER_LOCKERS:
        return "lockers";
      case TierId.TIER_SUPERVISOR:
        return "supervisor";
      case TierId.TIER_SELLER:
        return "seller";
      default:
        return "";
    }
  },
});

export const getLevelUser = (tier: number) => {
  switch (tier) {
    case TierId.TIER_ADMIN:
      return "administrator";
    case TierId.TIER_MANAGEMENT:
      return "management";
    case TierId.TIER_ACCOUNTING:
      return "accounting";
    case TierId.TIER_SUPPORT:
      return "support";
    case TierId.TIER_AGENCY:
      return "agency";
    case TierId.TIER_STORER:
      return "storer";
    case TierId.TIER_ASSISTANT:
      return "assistant";
    case TierId.TIER_DRIVER:
      return "driver";
    case TierId.TIER_DISPATCHER:
      return "dispatcher";
    case TierId.TIER_RRHH:
      return "humanResources";
    case TierId.TIER_LOCKERS:
      return "lockers";
    case TierId.TIER_SUPERVISOR:
      return "supervisor";
    case TierId.TIER_SELLER:
      return "seller";
    case 0:
      return "employee";
    default:
      return "";
  }
};

export const getRoleName = (role: Role, trasnlate: any): string => {
  return trasnlate == "es" ? role.name : role.name_en;
};

export const TIER_AGENCY = 5;
export const TIER_ADMIN = 1;
export const TIER_LOCKER = 11;
export const TIER_SELLER = 13;
export const TIER_SUPERVISOR = 12;

export const MEMBESHIP_BASIC = 0;
export const MEMBERSHIP_PREMIUM = 1;
export const MEMBERSHIP_CORPORATE = 2;
