<h1 style="text-align: center;"> {{ "Documents.title" | translate }} </h1>
<p class="text-justify">
    {{ "Documents.paragraph" | translate }}
</p>

<div class="card text-center">
    <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link active" routerLink="/documents">
            {{ "Documents.locker.locker" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/commission">
            {{ "Documents.commission.commission" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/payments">
            {{ "Documents.Payments.payment" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/example">
            {{ "Documents.TaxInsur.TaxInsur" | translate }}
            </a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <h1> {{ "Documents.locker.title" | translate }} </h1>
        <br />
        <p class="text-justify">
            {{ "Documents.locker.p1" | translate }}
        </p>
        <h3> {{ "Documents.locker.basic" | translate }} </h3>
        <p class="text-justify">
            <span class="space">
                ✓ {{ "Documents.locker.m1" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m3" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m2" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m4" | translate }}
            </span>
        </p>
        <br />
        <h3> {{ "Documents.locker.premium" | translate }} </h3>
        <p class="text-justify">
            <span class="space">
                ✓ {{ "Documents.locker.m1" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m3" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m5" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m6" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m7" | translate }}
            </span>
        </p>
        <br />
        <h3> {{ "Documents.locker.corporative" | translate }} </h3>
        <p class="text-justify">
            <span class="space">
            ✓ {{ "Documents.locker.m1" | translate }}    
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m3" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m8" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m9" | translate }}
            </span>
            <br />
            <span class="space">
                ✓ {{ "Documents.locker.m10" | translate }}
            </span>
        </p>

        <h4> {{ "Documents.locker.table" | translate }} </h4>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col"> {{ "Documents.locker.country" | translate }} </th>
                    <th scope="col"> {{ "Documents.locker.tax" | translate }} </th>
                    <th scope="col"> {{ "Documents.locker.insurance" | translate }} </th>
                    <th scope="col"> {{ "Documents.locker.nationalization" | translate }} </th>
                    <th scope="col"> {{ "Documents.locker.tfc" | translate }} </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="styleth"> {{ "Documents.locker.us" | translate }} </td>
                    <td>7%</td>
                    <td>5%</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td class="styleth">México</td>
                    <td>16%</td>
                    <td>12</td>
                    <td>0</td>
                    <td>$ 10.00</td>
                </tr>
                <tr>
                    <td class="styleth">Guatemala</td>
                    <td>15%</td>
                    <td>5%</td>
                    <td>15%</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td class="styleth">El Salvador</td>
                    <td>13%</td>
                    <td>5%</td>
                    <td>10%</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td class="styleth">Panamá</td>
                    <td>15%</td>
                    <td>5%</td>
                    <td>15%</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td class="styleth">Colombia</td>
                    <td>19%</td>
                    <td>5%</td>
                    <td>$ 6.00</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td class="styleth">Venezuela</td>
                    <td>16%</td>
                    <td>12%</td>
                    <td>$ 10.00</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>

        <p class="text-justify">
            <span class="sup">
                <sup>1</sup> {{ "Documents.locker.sup1" | translate }}
            </span>
            <br />
            <span class="sup">
                <sup>2</sup> {{ "Documents.locker.sup2" | translate }}
            </span>
            <br />
            <span class="sup">
                <sup>3</sup> {{ "Documents.locker.sup3" | translate }}
            </span>
            <br />
            <span class="sup">
                {{ "Documents.locker.sup" | translate }}
            </span>
        </p>

        <br />
        <p class="text-justify">
            {{ "Documents.locker.p3" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.locker.p4" | translate }}
        </p>
    </div>
</div>