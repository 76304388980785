<h5 class="card-header">{{ "conditions.t1" | translate }}</h5>
<br />
<p class="card-text">{{"conditions.p1" | translate }}</p>
<h5 class="card-text">{{"conditions.t2" | translate }}</h5>
<p class="card-text">{{"conditions.p2" | translate }}</p>
<h5 class="card-text">{{"conditions.t3" | translate }}</h5>
<p class="card-text"> {{"conditions.p3" | translate }}</p>
<h5 class="card-text">{{"conditions.t4" | translate }}</h5>
<p class="card-text"> {{"conditions.p4" | translate }}</p>
<h5 class="card-text">{{"conditions.t5" | translate }}</h5>
<p class="card-text"> {{"conditions.p5" | translate }}</p>
<h5 class="card-text">{{"conditions.t6" | translate }}</h5>
<p class="card-text"> {{"conditions.p6" | translate }}</p>
<h5 class="card-text">{{"conditions.t7" | translate }}</h5>
<p class="card-text"> {{"conditions.p7" | translate }}</p>
<h5 class="card-text">{{"conditions.t8" | translate }}</h5>
<p class="card-text"> {{"conditions.p8" | translate }}</p>
<h5 class="card-text">{{"conditions.t9" | translate }}</h5>
<p class="card-text"> {{"conditions.p9" | translate }}</p>
<h5 class="card-text">{{"conditions.t10" | translate }}</h5>
<p class="card-text"> {{"conditions.p10" | translate }}</p>
<h5 class="card-text">{{"conditions.t11" | translate }}</h5>
<p class="card-text"> {{"conditions.p11" | translate }}</p>
<h5 class="card-text">{{"conditions.t1" | translate }}</h5>
<p class="card-text"> {{"conditions.p1" | translate }}
    <br />
    <br />{{"conditions.p12a" | translate }}
    <br />{{"conditions.p12b" | translate }}
    <br />{{"conditions.p12c" | translate }}
    <br />{{"conditions.p12d" | translate }}
    <br />{{"conditions.p12e" | translate }}
    <br />{{"conditions.p12f" | translate }}
</p>