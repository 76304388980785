import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { OurservicesComponent } from './ourservices/ourservices.component';
import { Service001Component } from './service/service001/service001.component';
import { Service002Component } from './service/service002/service002.component';
import { Service003Component } from './service/service003/service003.component';
import { Service004Component } from './service/service004/service004.component';
import { Service005Component } from './service/service005/service005.component';
import { Service006Component } from './service/service006/service006.component';
import { Service007Component } from './service/service007/service007.component';
import { Service008Component } from './service/service008/service008.component';
import { Service009Component } from './service/service009/service009.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RestrictionsComponent } from './restrictions/restrictions.component';
import { MembershipComponent } from './membership/membership.component';
import { AgencyMapComponent } from './agency-map/agency-map.component';
import { QuestionsComponent } from './questions/questions.component';
import { DocumentsComponent } from './documents/documents.component';
import { paymentsComponent } from './payments/payments.component';
import { CommissionComponent } from './commission/commission.component';
import { ExampleComponent } from './example/example.component';
import { ConditionsComponent } from './conditions/conditions.component';
import { Component } from '@angular/core';

export const ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'services', component: OurservicesComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'restrictions', component: RestrictionsComponent },
  { path: 'service001', component: Service001Component },
  { path: 'service002', component: Service002Component },
  { path: 'service003', component: Service003Component },
  { path: 'service004', component: Service004Component },
  { path: 'service005', component: Service005Component },
  { path: 'service006', component: Service006Component },
  { path: 'service007', component: Service007Component },
  { path: 'service008', component: Service008Component },
  { path: 'service009', component: Service009Component },
  { path: 'membership', component: MembershipComponent },
  { path: 'map', component: AgencyMapComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'questions', component: QuestionsComponent },
  { path: 'payments', component: paymentsComponent },
  { path: 'commission', component: CommissionComponent },
  { path: 'example', component: ExampleComponent },
  { path: 'conditions', component: ConditionsComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];
