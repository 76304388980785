<h1 style="text-align: center;"> {{ "questions.title" | translate }} </h1>
<br />
<p class="text-justify">
    {{ "instructions" | translate }}
    <a (click)="goMail()" class="ref" target="_blank">
        support@cmlexports.com
    </a>
</p>
<div class="accordion" id="accordionExample">
    <div class="card">
        <div class="card-header" id="headingOne">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          {{ "questions.q1" | translate }}
        </button>
            </h2>
        </div>
        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a1" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          {{ "questions.q2" | translate }}
        </button>
            </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a2" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingThree">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          {{ "questions.q3" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a3" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingFour">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          {{ "questions.q4" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a4" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingFive">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          {{ "questions.q5" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a5" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingSix">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          {{ "questions.q6" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a6" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingSeven">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
          {{ "questions.q7" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a7" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingEight">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
          {{ "questions.q8" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a8" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingNine">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
          {{ "questions.q9" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a9" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingTen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
          {{ "questions.q10" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a10" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingEleven">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
          {{ "questions.q11" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a11" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingTwelve">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
          {{ "questions.q12" | translate }} 
        </button>
            </h2>
        </div>
        <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a12" | translate }} {{ "questions.link" | translate }}.
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingThirteen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
          {{ "questions.q13" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseThirteen" class="collapse" aria-labelledby="headingThirteen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a13" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingFourteen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
          {{ "questions.q14" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseFourteen" class="collapse" aria-labelledby="headingFourteen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a14" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingFifteen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
          {{ "questions.q15" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseFifteen" class="collapse" aria-labelledby="headingFifteen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a15" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingSixteen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
          {{ "questions.q16" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseSixteen" class="collapse" aria-labelledby="headingSixteen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a16" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingSeventeen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
          {{ "questions.q17" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseSeventeen" class="collapse" aria-labelledby="headingSeventeen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a17" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingEighteen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
          {{ "questions.q18" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseEighteen" class="collapse" aria-labelledby="headingEighteen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a18" | translate }}
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" id="headingNineteen">
            <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
          {{ "questions.q19" | translate}}
        </button>
            </h2>
        </div>
        <div id="collapseNineteen" class="collapse" aria-labelledby="headingNineteen" data-parent="#accordionExample">
            <div class="card-answer">
                {{ "questions.a19" | translate }}
            </div>
        </div>
    </div>
</div>