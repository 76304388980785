<div class="container">
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="1"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="2"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="3"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="4"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="5"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="6"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="7"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="8"></li>
            <li data-target="#carouselExampleSlidesOnly" data-slide-to="9"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img id="slide001" src="{{ 'SLIDE001' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE002' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE003' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE004' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE005' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE006' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE007' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE008' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE009' | translate }}" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
                <img src="{{ 'SLIDE010' | translate }}" class="d-block w-100" alt="...">
                <button (click)="SignUp()" type="button" class="btn btn-warning locker">{{'signUp' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="jumbotron jumbotron-fluid">
        <h1 class="display-4">CML Exports and Moving Solutions Inc</h1>
        <p class="lead justify">{{'TextHome' | translate }}
        </p>
    </div>
</div>