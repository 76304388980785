<div id="map-container">
  <agm-map
    [latitude]="location.lat"
    [longitude]="location.lng"
    [disableDefaultUI]="true"
    [zoom]="12"
    (centerChange)="centerChange($event)"
    (mapClick)="mapClick()"
    (mapRightClick)="mapClick()"
    (mapReady)="mapReady($event)"
  >
    <agm-marker
      *ngFor="let agency of query.length < 1 ? agencies : searchResults"
      (markerClick)="markerClick(agency)"
      [latitude]="agency.lat"
      [longitude]="agency.lng"
      [iconUrl]="{
        url: '/assets/pin.png',
        scaledSize: {
          width: 48,
          height: 48
        }
      }"
    ></agm-marker>
    <agency-map-info-window
      [isOpen]="isInfoWindowOpen"
      (isOpenChange)="isInfoWindowOpen = $event"
      [latitude]="currentAgency !== null ? currentAgency.lat : 0"
      [longitude]="currentAgency !== null ? currentAgency.lng : 0"
      [offset]="{
        top: '-28px',
        left: '0px'
      }"
      [maxWidth]="512"
      [maxHeight]="768"
      [padding]="85"
      #infoWindow
    >
      <ng-container *ngIf="currentAgency !== null">
        <div>
          <cml-display-user
            [user]="currentAgency"
            userAgency="true"
            inMap="true"
          ></cml-display-user>
        </div>
      </ng-container>
    </agency-map-info-window>
  </agm-map>
  <div id="search-panel" class="row justify-content-center mt-4 mx-2">
    <div class="col-12 col-sm-6 col-md-4" id="search-panel-content">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'search' | translate }}"
        (input)="queryChange($event.target.value)"
      />
      <div
        id="results-container"
        *ngIf="searchResults.length > 0 && showSearchResults"
      >
        <div
          class="result"
          *ngFor="let agency of searchResults"
          (click)="show(agency)"
        >
          {{ agency.person.name }}
        </div>
      </div>
    </div>
  </div>
</div>
