import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { LocationService } from "src/app/services/location.service";
import { environment } from "src/environments/environment";
import { User } from "./user";

export type GetWithLocationParams = { lat: number; lng: number };

type UserWithLocation = User & { lat: number; lng: number };

function addLocation(u: User): UserWithLocation {
  const location = u.person.lat_lng?.match(/(.*),(.*)/) ?? [];

  return { ...u, lat: Number(location[1]), lng: Number(location[2]) };
}

@Component({
  selector: "cml-agency-map",
  templateUrl: "./agency-map.component.html",
  styles: [
    `
      #map-container {
        border: 1px solid lightgray;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
      }

      #search-panel {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        pointer-events: none;
      }

      #search-panel-content {
        pointer-events: auto;
      }

      #results-container {
        margin-top: 0.5em;
        background-color: white;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
      }

      agm-map {
        height: 86vh;
      }

      .result {
        padding: 1em;
        cursor: pointer;
      }

      .result:hover {
        background: rgba(0, 0, 0, 0.06);
      }
    `,
  ],
})
export class AgencyMapComponent implements OnInit, OnDestroy {
  public location: google.maps.LatLngLiteral = {
    lat: environment.initialLatitude,
    lng: environment.initialLongitude,
  };
  public agencies: UserWithLocation[] = [];

  private centerChangeSubject = new Subject<google.maps.LatLngLiteral>();
  private centerChangeSubscription: Subscription;

  public searchResults: UserWithLocation[] = [];
  public query = "";
  public showSearchResults = false;

  private queryChangeSubject = new Subject<string>();
  private queryChangeSubscription: Subscription;

  public isInfoWindowOpen = false;

  public currentAgency: UserWithLocation | null = null;

  public map: google.maps.Map | undefined;

  private geocoder: google.maps.Geocoder;
  private autocompleteService: google.maps.places.AutocompleteService;

  constructor(
    private http: HttpClient,
    private locationService: LocationService
  ) {
    this.geocoder = new google.maps.Geocoder();
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  getByLocation = (params: GetWithLocationParams) => {
    return this.http
      .get<User[]>(
        `${environment.apiUrl}/agencies/locations?lat=${params.lat}&lng=${params.lng}`
      )
      .pipe(map((agencies) => agencies));
  };

  getByQuery = (value: string) => {
    return this.http
      .get<User[]>(`${environment.apiUrl}/agencies/query?query=${value}`)
      .pipe(map((agencies) => agencies));
  };

  ngOnInit(): void {
    this.centerChangeSubscription = this.centerChangeSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((newCenter) => {
        if (this.query) {
          return;
        }

        this.getAgenciesByLocation(newCenter);
      });

    this.queryChangeSubscription = this.queryChangeSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((newQuery) => {
        if (newQuery) {
          this.getAgenciesByQuery(newQuery);
        } else {
          this.searchResults = [];
          this.getAgenciesByLocation(this.location);
        }
      });

    this.locationService
      .getLocation()
      .then((location) => {
        this.location = {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        };
      })
      .finally(() => {
        this.getAgenciesByLocation(this.location);
      });
  }

  ngOnDestroy() {
    this.queryChangeSubscription.unsubscribe();
    this.centerChangeSubscription.unsubscribe();
  }

  getAgenciesByLocation(params: GetWithLocationParams) {
    this.getByLocation(params).subscribe(
      (agencies: User[]) => {
        agencies
          .map(addLocation)
          .filter((a) => !this.agencies.some((b) => b.id === a.id))
          .forEach((a) => {
            this.agencies.push(a);
          });
      },
      (error: HttpErrorResponse) => {}
    );
  }

  getAgenciesByQuery(query: string) {
    this.getByQuery(query).subscribe(
      async (agencies: User[]) => {
        this.showSearchResults = true;
        this.searchResults = agencies.map(addLocation);

        const cityGeometry = await this.getCityGeometry(
          query,
          this.location.lat,
          this.location.lng
        );

        if (cityGeometry) {
          this.showSearchResults = false;
          this.location = cityGeometry.location.toJSON();
          this.map?.fitBounds(cityGeometry.bounds);
        }
      },
      (error: HttpErrorResponse) => {}
    );
  }

  centerChange(event: google.maps.LatLngLiteral) {
    if (this.showSearchResults) {
      this.showSearchResults = false;
    }
    this.centerChangeSubject.next(event);
  }

  queryChange(query: string) {
    this.query = query;
    this.isInfoWindowOpen = false;
    this.queryChangeSubject.next(query);
  }

  show(agency: UserWithLocation) {
    this.showSearchResults = false;
    this.location = {
      lat: agency.lat,
      lng: agency.lng,
    };
    this.currentAgency = agency;
    this.isInfoWindowOpen = true;
  }

  markerClick(agency: UserWithLocation) {
    this.currentAgency = agency;
    this.isInfoWindowOpen = true;
  }

  mapClick() {
    this.isInfoWindowOpen = false;
    this.showSearchResults = false;
  }

  mapReady(map: google.maps.Map) {
    this.map = map;
  }

  async getCityGeometry(
    input: string,
    lat: number,
    lng: number
  ): Promise<google.maps.GeocoderGeometry | null> {
    try {
      const predictions = await promisify(
        this.autocompleteService.getPlacePredictions.bind(
          this.autocompleteService
        )
      )({
        input,
        location: new google.maps.LatLng(lat, lng),
        radius: 25000,
      });

      const name: string =
        predictions[0]?.structured_formatting?.main_text ?? "";

      if (!input || !name) {
        return null;
      }

      if (name.toLowerCase() !== input.toLowerCase()) {
        return null;
      }

      const results = await promisify(
        this.geocoder.geocode.bind(this.geocoder)
      )({
        placeId: predictions[0].place_id,
      });

      return results[0]?.geometry ?? null;
    } catch (err) {
      console.log("GoogleMapsService: getCityGeometry:", err);

      return null;
    }
  }
}

function promisify<R, T>(
  f: (request: R, callback: (result: T, status: "OK" | string) => void) => void
): (request: R) => Promise<T> {
  return (request: R) => {
    return new Promise((resolve, reject) => {
      f(request, (result, status) => {
        if (status === "OK") {
          resolve(result);
        } else {
          reject(new Error(status));
        }
      });
    });
  };
}
