<div class="card" style="width: 90%;">
    <img class="img-center" src="{{ 'SI_3' | translate }}" alt="{{ 'SN_3' | translate }}">
    <div class="media-body">
        <h2> {{ 'SN_3' | translate }} </h2>
        <p class="card-details"> {{ 'DS_3' | translate }}
            <a type="button" (click)="signUp()" class="badge badge-info" style="color: whitesmoke;"> {{'Link' | translate}} 
            </a> {{'DS_03' | translate}}
            <a [routerLink]="['/membership']">{{'infoMembership' | translate}} </a>
        </p>

        <a [routerLink]="['/services']" class="btn btn-outline-danger btn-block" style="padding: 10px;"> {{ 'Back' | translate }} </a>
    </div>
</div>