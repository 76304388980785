import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Service } from "./services/services.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  langs: string[] = [];

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    this.translate.addLangs(["es", "en"]);
    this.langs = this.translate.getLangs();
  }

  goFacebook() {
    const url = "https://www.facebook.com/cmlexports";
    window.open(url, "_blank");
  }

  goWhatsapp() {
    const url = "https://wa.me/message/OHALLVSURAWWL1";
    window.open(url, "_blank");
  }

  goTwitter() {
    const url = "https://www.twitter.com/cmlexports";
    window.open(url, "_blank");
  }

  goInstagram() {
    const url = "https://www.instagram.com/cmlexports";
    window.open(url, "_blank");
  }

  goMail() {
    const url = "mailto:support@cmlexports.com?subject=Contacto desde el sitio Web";
    window.open(url, "_blank");
  }

  goSkype(){
    const url = "https://join.skype.com/invite/tfxGKMQdZHUJ";
    window.open(url, "_blank");
  }
}
