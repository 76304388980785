<div class="container">
    <!-- SECTION: CMLGROUP -->
    <div class="media-body">
        <img class="lineal" src="/assets/img/LogosinLine.jpg" alt="CML Group">
        <p class="justify align-top">{{ 'CMLGROUP' | translate }}</p>
        <p class="justify tab-left">
            <span class="badge badge-primary badge-pill blue-color"> + </span> &nbsp; {{ 'points1' | translate }}</p>
        <p class="justify tab-left">
            <span class="badge badge-primary badge-pill blue-color"> + </span> &nbsp; {{ 'points2' | translate }} </p>
        <p class="justify tab-left">
            <span class="badge badge-primary badge-pill blue-color"> + </span> &nbsp; {{ 'points3' | translate }} </p>
        <img class="img-cml" src="/assets/img/CMLGroup.jpg" alt="CML Group">
    </div>

    <!-- SECTION: CMLEXPORTS -->
    <div class="media-body">
        <h1> CML EXPORTS </h1>
        <hr class="line-hr">

        <img class="img-right" src="{{ 'WORLDCML' | translate }}" alt="Always on Time">
        <br>
        <p class="justify"> {{ 'CMLEXPORTS_1' | translate }} </p>
        <p class="justify"> {{ 'CMLEXPORTS_2' | translate }} </p>
        <p class="justify"> {{ 'CMLEXPORTS_3' | translate }} </p>
        <p class="justify"> {{ 'CMLEXPORTS_4' | translate }} </p>
    </div>

    <!-- SECTION: TRUCKING -->
    <div class="media-body align-top">
        <h1> CML TRUCKING </h1>
        <hr class="line-hr">

        <img class="img-right" src="/assets/img/002Trucking.jpg" alt="CML Trucking">
        <p class="justify align-top"> {{ 'CMLTRUCKING_1' | translate }}</p>
        <p class="justify"> {{ 'CMLTRUCKING_2' | translate }} </p>
    </div>

    <!-- SECTION: CMLTRAVEL -->
    <div class="media-body align-top" height="auto;">
        <h1> CML TRAVEL </h1>
        <hr class="line-hr">

        <img class="img-right" src="{{ 'WORLDTRAVEL' | translate }}" alt="CML Travel">
        <br>
        <br>
        <p class="justify"> {{ 'CMLTRAVEL_1' | translate }} </p>
        <p class="justify"> {{ 'CMLTRAVEL_2' | translate }} </p>
        <p class="justify"> {{ 'CMLTRAVEL_3' | translate }} </p>

        <br>
        <br>

    </div>

    <!-- SECTION: CTIS -->
    <div class="media-body align-top" height="auto;">
        <h1> CTIS </h1>
        <hr class="line-hr">

        <img class="img-right" src="/assets/img/CTiS.png" alt="CTIS">
        <br>
        <br>
        <p class="justify"> {{ 'CTIS_1' | translate }} </p>
        <p class="justify"> {{ 'CTIS_2' | translate }} </p>

        <br>
        <br>

    </div>

</div>