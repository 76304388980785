import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  getLevelUser,
  getRoleName,
  MEMBERSHIP_CORPORATE,
  MEMBERSHIP_PREMIUM,
  MEMBESHIP_BASIC,
  TIER_LOCKER,
  TIER_SELLER,
  User,
} from "./user";

@Component({
  selector: "cml-display-user",
  templateUrl: "./display-user.component.html",
  styles: [`
    p.font-weight-bold {
      margin-bottom: 0.35rem !important;
    }
  `],
})
export class DisplayUserComponent {
  @Input() user: User;

  constructor(private translate: TranslateService) {}

  getNameRole = (user: User): string => {
    let role = !!user.role[0] ? user.role[0] : user.role;
    return getRoleName(role, this.translate.getDefaultLang());
  };

  getTierName = (user: User): string => {
    let tier = !!!Number.isInteger(user.tier)
      ? parseInt(user.tier + "")
      : user.tier;
    let name = getLevelUser(tier);
    return this.translate.instant("tierId." + name);
  };

  getNameUserAgency = (user: User): string => {
    switch (user.tier) {
      case TIER_LOCKER:
        if (user.reseller.length > 0) {
          return user.reseller[0].person.name;
        }
        if (!!user.agency[0]) {
          return user.agency[0].person.name;
        }
        return "";
      case TIER_SELLER:
        if (user.reseller.length > 0) {
          return user.reseller[0].person.name;
        }
        return user.agency[0].person.name;
      default:
        return !!user.agency[0]
          ? user.agency[0].person.name
          : user.agency.person.name;
    }
  };

  getUserNameBelongsTo = (): string => {
    if (!!this.user) {
      const { reseller, creator } = this.user;

      if (!!reseller && !!reseller.length) {
        return reseller[0].person.name;
      } else if (!!creator) {
        return creator.person.name;
      }
    }
  };

  getMembership = (): string => {
    switch (this.user.type_pay) {
      case MEMBESHIP_BASIC:
        return this.translate.instant("checkbox.basic");
      case MEMBERSHIP_PREMIUM:
        return this.translate.instant("checkbox.premium");
      case MEMBERSHIP_CORPORATE:
        return this.translate.instant("checkbox.corporate");
    }
  };

  classMembership = (): string => {
    switch (this.user.type_pay) {
      case MEMBESHIP_BASIC:
        return "alert-secondary";
      case MEMBERSHIP_PREMIUM:
        return "alert-info";
      case MEMBERSHIP_CORPORATE:
        return "alert-primary";
    }
  };
}
