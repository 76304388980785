import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl, DomSanitizer  } from '@angular/platform-browser';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  public url: SafeResourceUrl ;

  constructor(
    private translate: TranslateService,
    private satanizer: DomSanitizer)
     {
      let url= this.translate.instant('Documents.1_locker');
      this.url = this.satanizer.bypassSecurityTrustResourceUrl(url);
      }

  ngOnInit(): void {
  }

}
