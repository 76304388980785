<section>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <h5 class="text-primary text-center">
          {{ user.person.name }}
        </h5>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="user.type_pay !== null && user.tier === 11">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="alert" [ngClass]="classMembership()">
        <div class="form-group text-center">
          {{ "formBuilder.membership_user" | translate }}<br />
          <h6 class="text-center">{{ getMembership() }}</h6>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!user.agency">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-users"></i>
          {{ "cardText.agencyName" | translate }}:
        </p>
        <span>{{
          !!user.agency[0]
            ? user.agency[0].person.name
            : user.agency.person.name
        }}</span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!user.person.country">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-home"></i>
          {{ "cardText.address" | translate }}:
        </p>
        <span>{{ user.person.full_address }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-envelope"></i>
          {{ "cardText.email" | translate }}:
        </p>
        <a [href]="'mailto:' + user.email">{{ user.email }}</a>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!user.person.phone">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-phone"></i>
          {{ "cardText.fullPhone" | translate }}:
        </p>
        <a [href]="'tel:' + user.person.format_phone">
          {{ user.person.format_phone }}
        </a>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!user.person.cellphone">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-phone"></i>
          {{ "cardText.fullCellphone" | translate }}:
        </p>
        <a [href]="'tel:' + user.person.format_cellphone">
          {{ user.person.format_cellphone }}
        </a>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      !!user && ((!!user.reseller && !!user.reseller.length) || !!user.creator)
    "
  >
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-user"></i>
          {{ "tableRender.user" | translate }}:
        </p>
        <p class="userName" [title]="getUserNameBelongsTo()">
          {{ getUserNameBelongsTo() }}
        </p>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!user && !!user.es_date">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="form-group">
        <p class="font-weight-bold">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          {{ "formBuilder.admission_date" | translate }}:
        </p>
        <span> {{ user.es_date }} </span>
      </div>
    </div>
  </div>
</section>
