<footer class="ae-container-fluid rk-footer center">
    <div class="ae-grid ae-grid--collapse footer-menu">
        <br />
        <a routerLink="home" class="rk-menu__link"> 
            {{ "Home" | translate }}</a> |
        <a routerLink="about" class="rk-menu__link">
            {{ "About" | translate }}</a> |
        <a routerLink="services" class="rk-menu__link">
            {{ "Services" | translate }}</a> |
        <a routerLink="contact" class="rk-menu__link">
            {{ "Contact" | translate }}</a> |
        <a routerLink="terms" class="rk-menu__link">
            {{ "Terms" | translate }}</a> |
        <a routerLink="privacy" class="rk-menu__link">
            {{ "Privacy" | translate }}</a> |
        <a routerLink="restrictions" class="rk-menu__link">
            {{ "titleRestrictions" | translate }}</a> |
        <a routerLink="conditions" class="rk-menu__link">
            {{ "conditions.t1" | translate }}</a>

        <p class="rk-footer__text">
            <br />
            <button type="button" class="btn btn-outline-primary text-white btn-sm" (click)="changeLanguage('en')">
        {{ "English" | translate }}
      </button> &nbsp;
            <button type="button" class="btn btn-outline-primary text-white btn-sm" (click)="changeLanguage('es')">
        {{ "Spanish" | translate }}
      </button> &nbsp;
            <button (click)="onNavigate()" class="btn btn-outline-warning btn-sm">
        {{ "LogIn" | translate }}
            </button>
        </p>
        <p class="rk-footer__text">
            CML Group <span class="ae-u-bold">© </span>
            <span class="ae-u-bolder"> {{ anio }} CML Exports. </span> {{ "footer" | translate }}
        </p>
        <br />
    </div>
</footer>