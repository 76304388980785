import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core'; 
import { SafeResourceUrl, DomSanitizer  } from '@angular/platform-browser';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnInit {
   public url: SafeResourceUrl ;

   constructor(
     private translate: TranslateService,
     private satanizer: DomSanitizer)
     {
        let url= this.translate.instant('Documents.2_Commissions');
        this.url = this.satanizer.bypassSecurityTrustResourceUrl(url);
    }

  ngOnInit(): void {
  }

}
