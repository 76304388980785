import { Injectable } from "@angular/core";

@Injectable()
export class LocationService {
  getLocation = (timeout?: number): Promise<Position> =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: timeout,
        maximumAge: 0,
      });
    });
}
