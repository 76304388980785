import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [],
})
export class FooterComponent {
  langs: string[] = ['en'];

  anio: number = new Date().getFullYear();

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  onNavigate() {
    const url = 'https://system.cmlexports.com';
    window.open(url, '_blank');
  }
}
