import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Routes
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// translation
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';

// Component
import { AppComponent } from './app.component';
import { AboutComponent } from './Components/about/about.component';
import { AgencyMapInfoWindowComponent } from './Components/agency-map/agency-map-info-window.component';
import { AgencyMapComponent } from './Components/agency-map/agency-map.component';
import { DisplayUserComponent } from './Components/agency-map/display-user.component';
import { ROUTES } from './Components/app.routes';
import { ContactComponent } from './Components/contact/contact.component';
import { DetailsComponent } from './Components/details/details.component';
import { HomeComponent } from './Components/home/home.component';
import { MembershipComponent } from './Components/membership/membership.component';
import { OurservicesComponent } from './Components/ourservices/ourservices.component';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { RestrictionsComponent } from './Components/restrictions/restrictions.component';
import { Service001Component } from './Components/service/service001/service001.component';
import { Service002Component } from './Components/service/service002/service002.component';
import { Service003Component } from './Components/service/service003/service003.component';
import { Service004Component } from './Components/service/service004/service004.component';
import { Service005Component } from './Components/service/service005/service005.component';
import { Service006Component } from './Components/service/service006/service006.component';
import { Service007Component } from './Components/service/service007/service007.component';
import { Service008Component } from './Components/service/service008/service008.component';
import { Service009Component } from './Components/service/service009/service009.component';
import { FooterComponent } from './Components/shared/footer/footer.component';
import { NavbarComponent } from './Components/shared/navbar/navbar.component';
import { TermsComponent } from './Components/terms/terms.component';
import { QuestionsComponent } from './Components/questions/questions.component';
import { DocumentsComponent } from './Components/documents/documents.component';
import { paymentsComponent } from './Components/payments/payments.component';
import { CommissionComponent } from './Components/commission/commission.component';


// Pipes
import { TruncatePipe } from './Components/pipes/truncate.pipes';


// Services
import { SERVICES } from './services/services.service';
import { LocationService } from './services/location.service';
import { environment } from 'src/environments/environment';
import { ExampleComponent } from './Components/example/example.component';
import { ConditionsComponent } from './Components/conditions/conditions.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    OurservicesComponent,
    DetailsComponent,
    TruncatePipe,
    Service001Component,
    Service002Component,
    Service003Component,
    Service004Component,
    Service005Component,
    Service006Component,
    Service007Component,
    Service008Component,
    Service009Component,
    TermsComponent,
    PrivacyComponent,
    RestrictionsComponent,
    MembershipComponent,
    AgencyMapComponent,
    AgencyMapInfoWindowComponent,
    DisplayUserComponent,
    QuestionsComponent,
    DocumentsComponent,
    paymentsComponent,
    CommissionComponent,
    ExampleComponent,
    ConditionsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES, { useHash: true }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    AgmSnazzyInfoWindowModule,
  ],
  providers: [SERVICES, LocationService],
  bootstrap: [AppComponent],
})
export class AppModule { }
