import { Component, AfterViewInit, Input } from "@angular/core";
import { AgmSnazzyInfoWindow } from "@agm/snazzy-info-window";

@Component({
  selector: "agency-map-info-window",
  template:
    "<div #outerWrapper><div #viewContainer></div></div><ng-content></ng-content>",
})
export class AgencyMapInfoWindowComponent
  extends AgmSnazzyInfoWindow
  implements AfterViewInit {
  /**
   * A custom padding size around the content of the info window.
   */
  @Input() offset: { top: string; left: string } = { top: "0", left: "0" };

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this._snazzyInfoWindowInitialized.then(() => {
      this._nativeSnazzyInfoWindow._opts.offset = this.offset;
    });
  }
}
