import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service009',
  templateUrl: './service009.component.html',
  styles: []
})
export class Service009Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
