import { Component } from "@angular/core";

@Component({
  selector: "app-service003",
  templateUrl: "./service003.component.html",
  styles: [],
})
export class Service003Component {
  constructor() {}

  signUp() {
    const url = "https://system.cmlexports.com/auth/register";
    window.open(url, "_blank");
  }

  membership() {
    const url = "https://cmlexports.com/public/InfoCasillero_elSALVADOR.pdf";
    window.open(url,"_blank");
  }

}
