import { Component } from '@angular/core';
import { SERVICES, Service } from '../../services/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ourservices',
  templateUrl: './ourservices.component.html',
  styles: []
})
export class OurservicesComponent  {

  serv: Service [] = [];

  // tslint:disable-next-line:no-shadowed-variable
  constructor( private service: SERVICES,
               private router: Router
    ) {

    this.serv = this.service.getServices();
    console.log(this.serv);
   }

   seeServices( idx: number ) {
     this.router.navigate(['/details', idx]);
   }

}
