import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [],
})
export class HomeComponent {
  title = 'CMLExports';
  constructor(public translate: TranslateService) {
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('en');
  }

  SignUp() {
    const url = 'https://system.cmlexports.com/auth/register';
    window.open(url, '_blank');
  }
}
