<h1 style="text-align: center;"> {{ "Documents.title" | translate }} </h1>
<p class="text-justify">
    {{ "Documents.paragraph" | translate }}
</p>

<div class="card text-center">
    <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link" routerLink="/documents">
            {{ "Documents.locker.locker" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/commission">
            {{ "Documents.commission.commission" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/payments">
            {{ "Documents.Payments.payment" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link active" routerLink="/example">
            {{ "Documents.TaxInsur.TaxInsur" | translate }}
            </a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <h1> {{ "Documents.TaxInsur.title" | translate }} </h1>
        <p class="text-justify">
            {{ "Documents.TaxInsur.p1" | translate }}
        </p>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        {{ "Documents.TaxInsur.declaredValue" | translate }}
                        <br /> USD
                    </th>
                    <th scope="col">
                        {{ "Documents.TaxInsur.tax" | translate }}
                        <br /> 13%
                    </th>
                    <th scope="col">
                        {{ "Documents.TaxInsur.insuredValue" | translate }}<sup>1</sup>
                        <br /> USD
                    </th>
                    <th scope="col">
                        {{ "Documents.TaxInsur.insurance" | translate }}
                        <br /> 5%
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td> $ 50.00 </td>
                    <td> $ 6.50 </td>
                    <td> $ 100.00 </td>
                    <td> $ 5.00 </td>
                </tr>
                <tr>
                    <td> $ 100.00 </td>
                    <td> $ 13.00 </td>
                    <td> $ 100.00 </td>
                    <td> $ 5.00 </td>
                </tr>
                <tr>

                    <td rowspan="2" style="vertical-align: middle;"> $ 150.00 </td>
                    <td rowspan="2" style="vertical-align: middle;"> $ 19.50 </td>
                    <td> $ 100.00 </td>
                    <td> $ 150.00 </td>
                </tr>
                <tr>
                    <td> $ 5.00 </td>
                    <td> $ 7.50 </td>
                </tr>
            </tbody>
        </table>
        <p class="text-justify small">
            <sup>1</sup> {{ "Documents.TaxInsur.sup" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.TaxInsur.p2" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.TaxInsur.p3" | translate }}
        </p>
        <br />
        <p class="text-justify">
            {{ "Documents.TaxInsur.p4" | translate }}
            <a class="ref" (click)="onTax()">
                {{ "Documents.TaxInsur.taxElSalvador" | translate }}.
            </a>
        </p>
    </div>
</div>