import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service004',
  templateUrl: './service004.component.html',
  styles: []
})
export class Service004Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
