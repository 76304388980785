<div class="card-columns">
    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_1' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_1' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_1' | translate | truncate:70 }} </p>
            <a routerLink="/service001" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_2' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_2' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_2' | translate | truncate:70 }} </p>
            <a routerLink="/service002" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_3' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_3' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_3' | translate | truncate:70 }} </p>
            <a routerLink="/service003" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_4' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_4' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_4' | translate | truncate:70 }} </p>
            <a routerLink="/service004" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_5' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_5' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_5' | translate | truncate:70 }} </p>
            <a routerLink="/service005" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_6' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_6' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_6' | translate | truncate:70 }} </p>
            <a routerLink="/service006" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_7' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_7' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_7' | translate | truncate:70 }} </p>
            <a routerLink="/service007" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_8' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_8' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_8' | translate | truncate:70 }} </p>
            <a routerLink="/service008" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>

    <div class="card">
        <img class="card-img-top img-fluid" src="{{ 'SI_9' | translate }}">
        <div class="card-block">
            <h4 class="card-title"> {{ 'SN_9' | translate | truncate:25 }} </h4>
            <p class="card-text"> {{ 'DS_9' | translate | truncate:70 }} </p>
            <a routerLink="/service009" class="btn btn-outline-primary btn-block"> {{ 'See' | translate }} </a>
        </div>
    </div>
</div>