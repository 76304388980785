import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.scss']
})
export class ExampleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onTax() {
    const url = 'https://www.mh.gob.sv/pmh/es/Temas/Manual_Unico_de_Operaciones_Aduaneras/Proceso_de_despacho/Proceso_Aduanero/Impuestos_a_pagar.html';
    window.open(url, '_blank');
  }

}
