<div class="container">

    <div class="row">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-body">
                    <h1 class="card-title"> {{ 'Contact' | translate }} </h1>
                    <br />
                    <p class="contact"> {{ 'ContactBody' | translate }} </p>
                    <br />
                    <p class="contact text-justify">
                        <strong> {{ 'Address' | translate }}: </strong> <br />112 Bartram Walk #600877
                        <br /> ST Johns, FL 32260
                    </p>
                    <p class="contact">
                        <strong> {{ 'Ph' | translate }}: </strong>
                        <br /> (904) 460 2718
                    </p>

                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-body">
                    <img src="../assets/img/contact.jpg" width="100%" height="400px">
                </div>
            </div>
        </div>
    </div>


</div>