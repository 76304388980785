<h5 class="card-header">
    {{ "privacy.title" | translate }}</h5>
<br />
<p class="card-text"> {{"privacy.p1" | translate }} </p>
<p class="card-text"> {{"privacy.p2" | translate }}
    <br /><span class="spacing"> ► {{"privacy.op1" | translate }}</span>
    <Br /><span class="spacing"> ► {{"privacy.op2" | translate }}</span>
    <Br /><span class="spacing"> ► {{"privacy.op3" | translate }}</span>
    <Br /><span class="spacing"> ► {{"privacy.op4" | translate }}</span>
</p>

<h5 class="card-text">{{ "privacy.t1" | translate }}</h5>
<p class="card-text"> {{"privacy.p3" | translate }} <br />
    <br />{{"privacy.p4" | translate }} <br />
    <br />{{"privacy.p5" | translate }} <br />
    <br />{{"privacy.p6" | translate }} <br />
    <br />{{"privacy.p7" | translate }} <br />
    <br />{{"privacy.p8" | translate }}
</p>

<h5 class="card-text">{{ "privacy.t2" | translate }}</h5>
<p class="card-text"> {{"privacy.p9" | translate }} <br />
    <br />{{"privacy.p10" | translate }} <br />
    <br />{{"privacy.p11" | translate }} <br />
    <br />{{"privacy.p12" | translate }} <br />
    <br />{{"privacy.p13" | translate }}
    <br /><span class="spacing"> ► {{"privacy.opA" | translate }}</span>
    <Br /><span class="spacing"> ► {{"privacy.opB" | translate }}</span>
    <Br /><span class="spacing"> ► {{"privacy.opC" | translate }}</span>
</p>

<p class="card-text"> {{"privacy.p14" | translate }} <br />
    <br /> {{"privacy.p15" | translate }} <br />
    <br /> {{"privacy.p16" | translate }} </p>

<h5 class="card-text">{{ "privacy.t3" | translate }}</h5>
<p class="card-text"> {{"privacy.p17" | translate }} <br />
    <br /> {{"privacy.p18" | translate }} <br />
    <br /> {{"privacy.p19" | translate }} </p>