<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="['/home']">CMLExports</a>
    <button class="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-item nav-link" routerLink="home">
                {{ "Home" | translate }}
              </a>
            </li>
            <li class="nav-item">
                <a class="nav-item nav-link" routerLink="about">
                {{ "About" | translate }}
              </a>
            </li>
            <li class="nav-item">
                <a class="nav-item nav-link" routerLink="services">
                {{ "Services" | translate }}
              </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ "System" | translate }}
        </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" (click)="onNavigate()"> {{ "LogIn" | translate }} </a>
                    <a class="dropdown-item" routerLink="map">
                      {{ "Agency map" | translate }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" routerLink="documents">
                      {{ "Documents.title" | translate }}
                    </a>
                    <a class="dropdown-item" routerLink="questions">
                      {{ "questions.title" | translate }}
                    </a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-item nav-link" routerLink="contact">
                {{ "Contact" | translate }}
              </a>
            </li>
        </ul>
    </div>
    <div class="navbar-nav d-none d-lg-inline" routerLinkActive="active">
        <button type="button" class="btn btn-outline-primary text-white btn-sm" (click)="changeLanguage('en')">
        {{ "English" | translate }}
      </button> &nbsp;
        <button type="button" class="btn btn-outline-primary text-white btn-sm" (click)="changeLanguage('es')">
        {{ "Spanish" | translate }}
      </button>
    </div>

</nav>