<app-navbar></app-navbar>

<div class="container">
    <router-outlet></router-outlet>

    <div class="social">
        <ul>
            <!--li>
                <a (click)="goFacebook()" target="_blank">
                    <img src="../assets/img/social/facebook.png" width="35px" />
                </a>
            </li-->
            <li>
                <a (click)="goWhatsapp()" target="_blank">
                    <img src="../assets/img/social/whatsapp.png" width="35px" />
                </a>
            </li>
            <li>
                <a (click)="goSkype()">
                    <img src="../assets/img/social/skype.png" width="35px" />
                </a>
            </li>
            <li>
                <a (click)="goMail()">
                    <img src="../assets/img/social/mail.png" width="35px" />
                </a>
            </li>
            <li>
                <a (click)="goTwitter()" target="_blank">
                    <img src="../assets/img/social/twitter.png" width="35px" />
                </a>
            </li>
            <li>
                <a (click)="goInstagram()" target="_blank">
                    <img src="../assets/img/social/instagram.png" width="35px" />
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="footer">
    <app-footer> </app-footer>
</div>