import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service005',
  templateUrl: './service005.component.html',
  styles: []
})
export class Service005Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
