<h1 style="text-align: center;"> {{ "Documents.title" | translate }} </h1>
<p class="text-justify">
    {{ "Documents.paragraph" | translate }}
</p>

<div class="card text-center">
    <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link" routerLink="/documents">
            {{ "Documents.locker.locker" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link active" routerLink="/commission">
            {{ "Documents.commission.commission" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/payments">
            {{ "Documents.Payments.payment" | translate }}
          </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/example">
            {{ "Documents.TaxInsur.TaxInsur" | translate }}
            </a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <h1> {{ "Documents.commission.title" | translate }} </h1>
        <br />
        <h5 style="text-align: left;"> {{ "Documents.commission.inscription" | translate }} </h5>
        <p class="text-justify">
            {{ "Documents.commission.i1" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.commission.i2" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.commission.i3" | translate }}
        </p>
        <h5 style="text-align: left;"> {{ "Documents.commission.commissions" | translate }} </h5>
        <p class="text-justify">
            {{ "Documents.commission.c1" | translate }}
        </p>
        <p class="text-justify">
            {{ "Documents.commission.c2" | translate }}
        </p>
        <div class="alert alert-warning" role="alert">
            <p class="text-justify">
                {{ "Documents.commission.c3" | translate }}
            </p>
            <h4>
                {{ "Documents.commission.c4" | translate }}<sup>1</sup> {{ "Documents.commission.c5" | translate }}<sup>2</sup>
            </h4>
            <hr>
            <p class="text-justify">
                {{ "Documents.commission.c6" | translate }}
            </p>
            <h2>( 160 - 60 ) * 6% → 100 * 6% = 6 USD</h2>
        </div>
        <p class="text-justify small">
            <sup>1</sup> {{ "Documents.commission.c7" | translate }}
            <br />
            <sup>2</sup> {{ "Documents.commission.c8" | translate }}
        </p>
        <h3> {{ "Documents.commission.table" | translate }} </h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th rowspan="2" scope="col" style="vertical-align: middle;"> {{ "Documents.commission.type" | translate }} </th>
                    <th rowspan="2" scope="col" style="vertical-align: middle;"> {{ "Documents.commission.bonus" | translate }} </th>
                    <th colspan="2" scope="col"> {{ "Documents.commission.production" | translate }} </th>
                </tr>
                <tr>
                    <th scope="col"> {{ "Documents.commission.seller" | translate }} </th>
                    <th scope="col"> Supervisor </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="styleth" scope="row"> {{ "Documents.commission.seller" | translate }} </td>
                    <td> $ 10.00 USD </td>
                    <td> {{ "Documents.commission.apply" | translate }} </td>
                    <td> {{ "Documents.commission.apply" | translate }} </td>
                </tr>
                <tr>
                    <td class="styleth" scope="row"> {{ "Documents.commission.agency" | translate }} </td>
                    <td> $ 5.00 USD </td>
                    <td> 4% </td>
                    <td> 6% </td>
                </tr>
                <tr>
                    <td class="styleth" scope="row"> {{ "Documents.commission.locker" | translate }} </td>
                    <td> $ 3.00 USD </td>
                    <td> 2% </td>
                    <td> 4% </td>
                </tr>
            </tbody>
        </table>
        <br />
        <h3> {{ "Documents.commission.requeriments" | translate }} </h3>
        <p class="text-justify">
            {{ "Documents.commission.details" | translate }}
        </p>
        <p class="text-justify space">
            1. {{ "Documents.commission.doc1" | translate }}
            <br /> 2. {{ "Documents.commission.doc2" | translate }}
            <br /> 3. {{ "Documents.commission.doc3" | translate }}
        </p>
    </div>
</div>